import React, { Suspense, useEffect, useState } from "react";
import "./App.scss";
import Contexts from "./contexts";
import { ThemeConfiguration, themeData } from "./data/themeData";
import { Loading } from "./loading/Loading";
import WebAppRoutes from "./routes/WebAppRoutes";
import BackToTop from "./components/BackToTop/BackToTop";

const App = () => {
  const [themeConfig, setThemeConfig] = useState<ThemeConfiguration | null>(
    null
  );
  const navigationState = useState<React.ReactNode[] | null>(null);
  useEffect(() => {
    setThemeConfig(themeConfig ?? themeData);
  }, [themeConfig]);
  if (themeConfig === null || setThemeConfig === null)
    return <div>Loading</div>;

  return (
    <Contexts
      themeState={[themeConfig, setThemeConfig]}
      navigationState={navigationState}
    >
      <Suspense fallback={<Loading />}>
        <WebAppRoutes />
        <BackToTop />
      </Suspense>
    </Contexts>
  );
};

export default App;
