import one from "../assets/svg/projects/one.svg";
import two from "../assets/svg/projects/two.svg";
import three from "../assets/svg/projects/three.svg";
import four from "../assets/svg/projects/four.svg";
import five from "../assets/svg/projects/five.svg";
// import six from "../assets/svg/projects/six.svg";
// import seven from "../assets/svg/projects/seven.svg";
// import eight from "../assets/svg/projects/eight.svg";

export const projectsData = [
  {
    id: 1,
    projectName: "Quoting Platform - Silveus Insurance ",
    projectDesc: "This project aims to build a Crop Insurance Products",
    tags: ["C#", "React", "Redux", "Material UI"],
    code: "",
    demo: "",
    image: one,
  },
  {
    id: 2,
    projectName: "Calibration Management Software",
    projectDesc:
      "Calibration software helps companies ensure their fixed assets are functioning within optimal parameters by performing tests on equipment and comparing results with standards.",
    tags: ["C#", "React", "React Query", "DevExpress"],
    code: "",
    demo: "",
    image: two,
  },
  {
    id: 3,
    projectName: "TRADE.IX",
    projectDesc: "Blockchain based a trade finance solution.",
    tags: ["C#", "Angular", "Blockchain"],
    code: "",
    demo: "",
    image: three,
  },
  {
    id: 4,
    projectName: "Customer Loyalty Solution",
    projectDesc:
      "This project aims to build Customer Loyalty Program.",
    tags: ["C#", "Asp.Net mvc", "Oracle"],
    code: "",
    demo: "",
    image: four,
  },
  {
    id: 5,
    projectName: "Visual SuperScript",
    projectDesc: "Visual Superscript (VSS) is the collaborative effort of dozens of pharmacists. VSS is packed with all the features an Independent Pharmacies needs to run their business smoothly and efficiently",
    tags: ["C#", "WPF", "DevExpress"],
    code: "",
    demo: "",
    image: five,
  }
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
