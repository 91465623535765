import React, { createContext, useContext, useState } from "react";

import { ThemeConfiguration } from "../data/themeData";
import axios from "axios";

export type ThemeContextDef = [
  ThemeConfiguration,
  React.Dispatch<React.SetStateAction<ThemeConfiguration | null>>
];
export const ThemeContext = createContext<ThemeContextDef>(
  [] as unknown as ThemeContextDef
);
export const useThemeContext = () => useContext(ThemeContext);

export const ClientContext = React.createContext(axios);
export const useClientContext = () => useContext(ClientContext);

export type NavContextDef = [
  React.ReactNode[] | null,
  React.Dispatch<React.SetStateAction<React.ReactNode[] | null>>
];
export const NavContext = React.createContext<NavContextDef>(
  [] as unknown as NavContextDef
);
export const useNavContext = () => useContext(NavContext);

// interface Props {
//     children: React.JSX.Element;
//   }
// export const ThemeContextProvider = (props : Props) => {
//     // eslint-disable-next-line
//     const [theme, setTheme] = useState(themeData.theme)
//     const [drawerOpen, setDrawerOpen] = useState(false)

//     const setHandleDrawer = () => {
//         setDrawerOpen(!drawerOpen)
//     }

//     const value = { theme, drawerOpen, setHandleDrawer }
//     return (
//         <ThemeContext.Provider value={value}>
//             {props.children}
//         </ThemeContext.Provider>
//     )
// }
