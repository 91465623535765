//import resume from '../assets/pdf/resume.pdf'
import avaterImage from "../assets/png/Sam - 1_BW.png";
import avaterImage1 from "../assets/png/Sam - 1.png";
import avaterImage2 from "../assets/png/Sayem.jpg"

export const headerData = {
  name: "Sayem",
  title: "Principal Software Architect",
  desciption:
    `As a Software Architect with over a decade of experience in designing and implementing scalable, high-performance software solutions, I am passionate about leveraging technology to solve complex business challenges. My journey in the tech industry has equipped me with a diverse skill set that encompasses software development, system architecture, and team leadership.`,
  image: avaterImage2,
  resumePdf: "https://avatars.githubusercontent.com/u/15273731",
};
