import JU from "../assets/svg/education/JU.svg";
import UIU from "../assets/svg/education/UIU.svg";

export const educationData = [
    {
        id: 1,
        institution: 'Jahangirnagar University',
        institutionLogo: JU,
        course: 'Masters in Applied Statistics and Data Science',
        startYear: '2023',
        endYear: '2024'

    },
    {
        id: 2,
        institution: 'United International University',
        institutionLogo: UIU,
        course: 'B.Sc. in Computer Science and Engineering',
        startYear: '2005',
        endYear: '2008'
    },
]