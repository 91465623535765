import { Fade } from "react-awesome-reveal";
import { makeStyles } from "@material-ui/core/styles";

import expImgWhite from "../../assets/svg/experience/expImgWhite.svg";
import expImgBlack from "../../assets/svg/experience/expImgBlack.svg";

import "./Experience.scss";
import { useThemeContext } from "../../contexts";
interface Props {
  id: number;
  company: string;
  companyLogo: string;
  jobtitle: string;
  startYear: string;
  endYear: string;
}
function ExperienceCard({ id, company, companyLogo, jobtitle, startYear, endYear }: Props) {
  const [theme] = useThemeContext();

  const useStyles = makeStyles((t) => ({
    experienceCard: {
      backgroundColor: theme.theme.palette.custom.primary30,
      "&:hover": {
        backgroundColor: theme.theme.palette.custom.primary50,
      },
    },
  }));

  const classes = useStyles();

  return (
    <Fade direction="up" style={{ width: "100%" }}>
      <div key={id} className={`experience-card ${classes.experienceCard}`}>
        <div
          className="expcard-img"
          style={{ backgroundColor: theme.theme.palette.custom.primary }}
        >
          <img
            src={
              theme.theme.palette.custom.type === "light"
                ? companyLogo
                : companyLogo
            }
            alt=""
          />
        </div>
        <div className="experience-details">
          <h6 style={{ color: theme.theme.palette.custom.primary }}>
            {startYear}-{endYear}
          </h6>
          <h4 style={{ color: theme.theme.palette.custom.tertiary }}>
            {jobtitle}
          </h4>
          <h5 style={{ color: theme.theme.palette.custom.tertiary80 }}>
            {company}
          </h5>
        </div>
      </div>
    </Fade>
  );
}

export default ExperienceCard;
