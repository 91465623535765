import { Fade } from "react-awesome-reveal";

import placeholder from "../../../assets/png/placeholder.png";
import "./SingleBlog.scss";
import { useThemeContext } from "../../../contexts";

interface Props {
  title: string;
  desc: string;
  date: string;
  image: string;
  url: string;
  id: number;
}

const SingleBlog = ({ title, desc, date, image, url, id }: Props) => {
  const [theme] = useThemeContext();
  return (
    <Fade direction="up">
      <a
        className="singleBlog"
        key={id}
        href={url}
        target="_blank"
        rel="noreferrer"
        style={{ backgroundColor: theme.theme.palette.custom.primary400 }}
      >
        <div
          className="singleBlog--image"
          style={{ backgroundColor: theme.theme.palette.custom.secondary }}
        >
          <img src={image ? image : placeholder} alt={title} />
        </div>
        <div className="singleBlog--body">
          <p style={{ color: theme.theme.palette.custom.tertiary }}>{date}</p>
          <h3 style={{ color: theme.theme.palette.custom.secondary }}>
            {title}
          </h3>
          <h6 style={{ color: theme.theme.palette.custom.secondary }}>
            {desc}
          </h6>
        </div>
      </a>
    </Fade>
  );
};

export default SingleBlog;
