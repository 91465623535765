import axios from "axios";
import { HTMLAttributes } from "react";
import {
  ClientContext,
  NavContext,
  NavContextDef,
  ThemeContext,
  ThemeContextDef,
} from "./Contexts";

export * from "./Contexts";

interface Props extends HTMLAttributes<JSX.Element> {
  themeState: ThemeContextDef;
  navigationState: NavContextDef;
}

export const Contexts = ({ themeState, navigationState, children }: Props) => {
  return (
    <ClientContext.Provider value={axios}>
      <ThemeContext.Provider value={themeState}>
        <NavContext.Provider value={navigationState}>
          {children}
        </NavContext.Provider>
      </ThemeContext.Provider>
    </ClientContext.Provider>
  );
};

export default Contexts;
