import { useThemeContext } from "../../contexts";
import { experienceData } from "../../data/experienceData";
import "./Experience.scss";

import ExperienceCard from "./ExperienceCard";

const Experience = () => {
  const [theme] = useThemeContext();
  return (
    <div
      className="experience"
      id="experience"
      style={{ backgroundColor: theme.theme.palette.custom.secondary }}
    >
      <div className="experience-body">
        <div className="experience-image">
          <img src={theme.theme.palette.custom.expimg} alt="" />
        </div>
        <div className="experience-description">
          <h1 style={{ color: theme.theme.palette.custom.primary }}>Experience</h1>
          {experienceData.map((exp) => (
            <ExperienceCard
              key={exp.id}
              id={exp.id}
              jobtitle={exp.jobtitle}
              company={exp.company}
              startYear={exp.startYear}
              endYear={exp.endYear}
              companyLogo={exp.companyLogo}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Experience;
