export const socialsData = {
    github: 'https://github.com/gsayem',
    facebook: 'https://www.facebook.com/s4sayem/',
    linkedIn: 'https://www.linkedin.com/in/s4sayem/',
    instagram: 'https://www.instagram.com/s4sayem/',
    codepen: undefined,
    twitter: 'https://twitter.com/s4sayem',
    reddit: undefined,
    blogger: undefined,
    medium: undefined,
    stackOverflow: 'https://stackoverflow.com/users/4042445/sayem',
    gitlab: undefined,
    youtube: undefined,
    xing:'https://www.xing.com/profile/MohammadAbu_Sayem/web_profiles'
}