import axios from 'axios';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import { ReactQueryWrapper } from './ReactQueryWrapper';
import App from './App';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

axios.defaults.baseURL = '/';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')!;
const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);

root.render(  
  <BrowserRouter basename={baseUrl}>
    <ReactQueryWrapper>
      <App />
    </ReactQueryWrapper>
  </BrowserRouter>  
);
