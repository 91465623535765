import React, { HTMLAttributes } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider, QueryErrorResetBoundary } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ErrorBoundaryFallback from './ErrorBoundaryFallback';

/** Wraps QueryClientProvider around App, outside of App.js, so that App has access to react-query useQuery. */
export const ReactQueryWrapper = ({ children }: HTMLAttributes<typeof QueryClientProvider>) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 5 * 60 * 1000
      }
    }
  });

  return (
    <React.Fragment>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onReset={reset}>
            <QueryClientProvider client={queryClient}>
              {children}
              <ReactQueryDevtools initialIsOpen={false} position ='bottom-right' />
            </QueryClientProvider>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </React.Fragment>
  );
};
