import { Card } from "@material-ui/core";

interface Props {
  error: Error;
  reset?: () => void;
}

export const ErrorBoundaryFallback = ({ error, reset }: Props) => {
  return (
    <Card role='alert' className='h-100'>
      {/* <Card.Header>
        <Card.Title>An error has occurred in the application</Card.Title>
        <Card.Subtitle>We apologize for any inconvenience that this may have caused you, and will get this fixed as soon as possible.</Card.Subtitle>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>This specific error was: {error.message}</Col>
        </Row>
        <Row>
          <Col>
            <Button onClick={reset}>Try again</Button>
          </Col>
        </Row>
      </Card.Body> */}
    </Card>
  );
};

export default ErrorBoundaryFallback;
