import { Button } from "@material-ui/core";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { makeStyles } from "@material-ui/core/styles";
import "./Landing.scss";
import "./Avatar.scss";
import { headerData } from "../../data/headerData";

import { FaXTwitter } from "react-icons/fa6";
import {
  FaTwitter,
  FaLinkedin,
  FaGithub,
  FaXing,
  FaBlogger,
  FaStackOverflow,
} from "react-icons/fa";
import { useThemeContext } from "../../contexts";
import { socialsData } from "../../data/socialsData";
import { allTheme } from "../../theme";
import { useCallback, useEffect, useState } from "react";
import { themeData } from "../../data/themeData";

export const Landing = () => {
  const [themeConfiguration, setThemeConfig] = useThemeContext();
  const [theme, setTheme] = useState(themeConfiguration.theme);
  useEffect(() => {
    setThemeConfig(themeConfiguration ?? themeData);
    setTheme(themeConfiguration.theme);
  }, [setThemeConfig, themeConfiguration]);

  const onContact = useCallback(async () => {
    const min = 1;
    const rn = Math.floor(Math.random() * (allTheme.length - min + 1) + min);
    console.log(rn.toString() + allTheme[rn]);
    themeConfiguration.theme = allTheme[rn];
    setThemeConfig(themeConfiguration);
    setTheme(allTheme[rn]);
  }, [themeConfiguration, setThemeConfig]);

  const useStyles = makeStyles((t) => ({
    resumeBtn: {
      color: theme.palette.custom.primary,
      borderRadius: "30px",
      textTransform: "inherit",
      textDecoration: "none",
      width: "150px",
      fontSize: "1rem",
      fontWeight: 500,
      height: "50px",
      fontFamily: "var(--primaryFont)",
      border: `3px solid ${theme.palette.custom.primary}`,
      transition: "100ms ease-out",
      "&:hover": {
        backgroundColor: theme.palette.custom.tertiary,
        color: theme.palette.custom.secondary,
        border: `3px solid ${theme.palette.custom.tertiary}`,
      },
      [theme.breakpoints.down("sm")]: {
        width: "180px",
      },
    },
    contactBtn: {
      backgroundColor: theme.palette.custom.primary,
      color: theme.palette.custom.secondary,
      borderRadius: "30px",
      textTransform: "inherit",
      textDecoration: "none",
      width: "150px",
      height: "50px",
      fontSize: "1rem",
      fontWeight: 500,
      fontFamily: "var(--primaryFont)",
      border: `3px solid ${theme.palette.custom.primary}`,
      transition: "100ms ease-out",
      "&:hover": {
        backgroundColor: theme.palette.custom.secondary,
        color: theme.palette.custom.tertiary,
        border: `3px solid ${theme.palette.custom.tertiary}`,
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className="landing">
      <div className="landing--container">
        <div
          className="landing--container-left"
          style={{ backgroundColor: theme.palette.custom.primary }}
        >
          <div className="lcl--content">
            {socialsData.linkedIn && (
              <a href={socialsData.linkedIn} target="_blank" rel="noreferrer">
                <FaLinkedin
                  className="landing--social"
                  style={{ color: theme.palette.custom.secondary }}
                  aria-label="LinkedIn"
                />
              </a>
            )}
            {socialsData.github && (
              <a href={socialsData.github} target="_blank" rel="noreferrer">
                <FaGithub
                  className="landing--social"
                  style={{ color: theme.palette.custom.secondary }}
                  aria-label="GitHub"
                />
              </a>
            )}
            {socialsData.twitter && (
              <a href={socialsData.twitter} target="_blank" rel="noreferrer">
                <FaXTwitter
                  className="landing--social"
                  style={{ color: theme.palette.custom.secondary }}
                  aria-label="Twitter"
                />
              </a>
            )}
            {socialsData.stackOverflow && (
              <a href={socialsData.stackOverflow} target="_blank" rel="noreferrer">
                <FaStackOverflow
                  className="landing--social"
                  style={{ color: theme.palette.custom.secondary }}
                  aria-label="StackOverflow"
                />
              </a>
            )}
            {socialsData.xing && (
              <a href={socialsData.xing} target="_blank" rel="noreferrer">
                <FaXing
                  className="landing--social"
                  style={{ color: theme.palette.custom.secondary }}
                  aria-label="XING"
                />
              </a>
            )}
          </div>
        </div>
        <img
          src={headerData.image}
          alt="Mohammad Abu Sayem"
          className="landing--img img-avatar"
          // style={{
          //   opacity: `${themeConfiguration.drawerOpen ? "0" : "1"}`,
          //   borderColor: theme.palette.custom.secondary,
          // }}
        />
        <div
          className="landing--container-right"
          style={{ backgroundColor: theme.palette.custom.secondary }}
        >
          <div
            className="lcr--content"
            style={{ color: theme.palette.custom.tertiary }}
          >
            <h6>{headerData.title}</h6>
            <h1>{headerData.name}</h1>
            <p style={{ textAlign: "justify", textJustify: "inter-word" }}>{headerData.desciption}</p>

            {/* <div className="lcr-buttonContainer">
              {headerData.resumePdf && (
                <a
                  href={headerData.resumePdf}
                  download="resume"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button className={classes.resumeBtn}>Download CV</Button>
                </a>
              )}
              <NavLink to="/#contacts" smooth={true}>
                <Button onClick={onContact} className={classes.contactBtn}>
                  Contact
                </Button>
              </NavLink>              
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
