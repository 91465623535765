/* eslint-disable */
import { Theme } from "@mui/material/styles";
import {
  greenThemeLight,
  greenThemeDark,
  bwThemeLight,
  bwThemeDark,
  blueThemeLight,
  blueThemeDark,
  redThemeLight,
  redThemeDark,
  orangeThemeLight,
  orangeThemeDark,
  purpleThemeLight,
  purpleThemeDark,
  pinkThemeLight,
  pinkThemeDark,
  yellowThemeLight,
  yellowThemeDark,
  ThemeProps,
} from "../theme/theme";

export interface ThemeConfiguration {
  theme: Theme;
  drawerOpen: boolean;
}
export const themeData: ThemeConfiguration = {
  theme: bwThemeDark,
  drawerOpen: false,
};

// Choose theme from above
