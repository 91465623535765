import { Helmet } from "react-helmet";
import {
  Navbar,
  Footer,
  Landing,
  About,
  Skills,
  Testimonials,
  Blog,
  Education,
  Experience,
  Contacts,
  Projects,
  Services,
  Achievement,
} from "../components";
import { headerData } from "../data/headerData";

export const Main = () => {
  return (
    <div>
      <Helmet>
        <title>{headerData.name} - Porfolio</title>
      </Helmet>
      <Navbar />
      <Landing />
      {/* <About /> */}
      <Education />
      {/* <Skills /> */}
      <Experience />
      {/* <Projects />
      <Achievement />
      <Services />
      <Testimonials />
      <Blog />
      <Contacts /> */}
      {/* <Footer /> */}
    </div>
  );
};

export default Main;
