import BigBarta from '../assets/svg/experience/BigBarta.svg'
import BitMascot from '../assets/svg/experience/BitMascot.svg'
import Aprosoft from '../assets/svg/experience/Aprosoft.svg'
import MirTech from '../assets/svg/experience/MirTech.svg'
import IT_MAGNET from '../assets/svg/experience/IT_MAGNET.svg'
import LEADS from '../assets/svg/experience/LEADS.svg'
import CSRL from '../assets/svg/experience/CSRL.svg'
import expImgWhite from '../assets/svg/experience/expImgWhite.svg'
import SELISE from '../assets/svg/experience/SELISE.svg'

export const experienceData = [
  {
    id: 0,
    company: "SELISE Digital Platforms",
    companyLogo: SELISE,
    jobtitle: "Principal Software Architect",
    startYear: "September' 2024",
    endYear: "Present",
  },
  {
    id: 1,
    company: "Big Barta Inc.",
    companyLogo: BigBarta,
    jobtitle: "Senior Software Architect",
    startYear: "November' 2017",
    endYear: "September' 2024",
  },
  {
    id: 2,
    company: "Bit Mascot (Pvt.) Ltd.",
    companyLogo: BitMascot,
    jobtitle: "Senior Software Engineer (Technical Lead)",
    startYear: "September'2015",
    endYear: "October' 2017",
  },
  {
    id: 3,
    company: "Aprosoft Consulting and Training Corp Limited",
    companyLogo: Aprosoft,
    jobtitle: "Senior Software Engineer",
    startYear: "January' 2013",
    endYear: "August' 2015",
  },
  {
    id: 4,
    company: "Mir Technologies Limited",
    companyLogo: MirTech,
    jobtitle: "Development Team Lead",
    startYear: "March' 2012",
    endYear: "December' 2012",
  },
  {
    id: 5,
    company: "IT Magnet Pty Ltd",
    companyLogo: IT_MAGNET,
    jobtitle: "Analyst Programmer",
    startYear: "April' 2011",
    endYear: "February' 2012",
  },
  {
    id: 6,
    company: "LEADS Corporation Limited",
    companyLogo: LEADS,
    jobtitle: "Senior Software Engineer",
    startYear: "September' 2009",
    endYear: "March' 2011",
  },
  {
    id: 7,
    company: "CSL Software Resources Ltd",
    companyLogo: CSRL,
    jobtitle: "Programmer",
    startYear: "July' 2007",
    endYear: "August' 2009",
  },
  {
    id: 8,
    company: "Microimage Bangladesh",
    companyLogo: expImgWhite,
    jobtitle: "Programmer",
    startYear: "January' 2006",
    endYear: "June' 2007",
  },
];
