import { Navigate, Route, Routes } from "react-router-dom";
import Main from "../layout/Main";
import BlogPage from "../pages/Blog/BlogPage";
import ProjectPage from "../pages/Project/ProjectPage";
import Layout from "../layout/Layout";

export const WebAppRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/" replace={true} />} />
      <Route path="/" element={<Layout />}>
        <Route index element={<Main />} />
        <Route path="blog" element={<BlogPage />} />
        <Route path="projects" element={<ProjectPage />} />        
      </Route>
    </Routes>
  );
};
export default WebAppRoutes;
